import { gql } from './__generated__';

export const dayTimeFragment = gql(`fragment DayTime on CalendarDayTime {
	day {
		year
		month
		day
	}
	time {
		hour
		minute
	}
}`);

export const dayFragment = gql(`fragment Day on CalendarDay {
	year
	month
	day
}`);

export const timeFragment = gql(`fragment Time on CalendarTime {
	hour
	minute
}`);

export const currencyFragment = gql(`fragment Currency on Currency {
	value
	code
	displayValue
}`);

export const addressFragment = gql(`fragment Address on Address {
	street
	street2
	city
	state
	country
	postalCode
}`);
